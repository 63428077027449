import { TFunction } from 'i18next';

import { getOrganizationTypeSuffix } from './getOrganizationTypeSuffix';

import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import { ResumeItem } from '@typings/graphql';

export const getOrganizationLocationAndSuffix = (
  organizationGroup: ResumeItem,
  t?: TFunction
) => {
  const { organizationType, address } = organizationGroup;

  return `${[getPhysicalLocation(address)]
    .filter(Boolean)
    .join(', ')} ${getOrganizationTypeSuffix(organizationType, t)}`;
};
