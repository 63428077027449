import { gql } from '@apollo/client';

export default gql`
  query fetchIndividualMemberships($id: String!) {
    getMembershipHistory(id: $id) {
      id
      type
      admissionDate
      terminationReasonId
      terminationDate
    }
  }
`;
