import { PhoneType } from '@typings/graphql';

export const getPhoneTypeLabel = (t: any, type: PhoneType) => {
  switch (type) {
    case PhoneType.Business:
      return t('phone-type.business', 'Business');
    case PhoneType.Home:
      return t('phone-type.home', 'Home');
    case PhoneType.Mobile:
      return t('phone-type.mobile', 'Mobile');
  }
};
