import { gql } from '@apollo/client';

export const fetchCountries = gql`
  query Countries {
    countries {
      id
      name
      shortCode
    }
  }
`;
