import { TFunction } from 'i18next';

export const getFormattedYearsOfService = (
  yearsInput: number,
  t: TFunction
) => {
  const yearsCount = parseInt(String(yearsInput), 10);
  const monthsCount = Math.round((yearsInput - yearsCount) * 12);

  const formattedYears = t(
    'view-profile.resume.years-of-service-count',
    '{{yearsCount}} years',
    {
      yearsCount,
    }
  );

  const formattedMonths = t(
    'view-profile.resume.months-of-service',
    '{{monthsCount}} months',
    {
      monthsCount,
    }
  );

  if (yearsCount && !monthsCount) {
    return formattedYears;
  }

  if (!yearsCount && monthsCount) {
    return formattedMonths;
  }

  return `${formattedYears} ${formattedMonths}`;
};
