import React from 'react';
import { useTranslation } from '@external/react-i18next';
import Icon from '@components/Icon';
import Link from '@components/Link';

interface Props {
  path: string;
  component: string;
}

const LinkEdit: React.FC<Props> = ({ path, component }) => {
  const { t } = useTranslation();
  return (
    <Link
      to={path}
      className="edit-link ml-2 link-styles-off flex items-center inline-block h5 alternate text-bright-blue-600 flex"
    >
      <Icon name="ui/edit" size="12" className="mr-2" />
      <span>
        {t('user-profile.edit-link-label', 'Edit')}
        <span className="sr-only">{` ${component}`}</span>
      </span>
    </Link>
  );
};

export default LinkEdit;
