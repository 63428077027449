import React from 'react';

import Divider from '@components/Divider';
import LinkEdit from '@components/LinkEdit';
import Loading from '@components/Loading';
import ContactInformationAddress from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationAddress';
import ContactInformationEmail from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationEmail';
import ContactInformationPhone from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationPhone';

import { useErrorHandling } from '@use-cases/notifications';

import { useFetchRejoinInfo } from '@repositories/leads/hooks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const RejoinFormComponent: React.FC = () => {
  const { user } = useAppConfig();
  const { t } = useTranslation();
  const individualId = user?.individualId;
  const { data, loading, error } = useFetchRejoinInfo(individualId || '');

  useErrorHandling(
    t('rejoin-form.query.error', 'An error occurred.'),
    error !== undefined,
    'rejoin-view.error'
  );
  if (error || !individualId) {
    return <p>error</p>;
  }

  if (!data || loading) {
    return <Loading />;
  }

  const {
    firstName,
    lastName,
    primaryEmail,
    primaryAddress,
    primaryPhone,
    otherPhones,
    otherAddresses,
    otherEmails,
  } = data.rejoinInfo;

  return (
    <>
      <Divider />
      <div className="flex justify-between items-start mt-10">
        <span className="text-sm" data-testid="rejoin-form-info">
          {t(
            'rejoin-form.description.info',
            'Changing information on this form will update your My Rotary profile.'
          )}
        </span>

        <LinkEdit
          path="/join-form/rejoin/edit-profile"
          component={t(
            'rejoin-contact-information.title',
            'Contact Information'
          )}
        />
      </div>
      <div className="desktop:mt-10 mobile:mt-6">
        <h3 className="h5">
          {t('rejoin-contact-information.first-name', 'First name')}
        </h3>
        <p>{firstName}</p>
      </div>
      <div className="desktop:mt-10 mobile:mt-7">
        <h3 className="h5">
          {t('rejoin-contact-information.last-name', 'Last name')}
        </h3>
        <p>{lastName}</p>
      </div>
      {primaryEmail && (
        <div className="desktop:mt-10 mobile:mt-6">
          <h3 className="h5">
            {t('rejoin-contact-information.email-primary', 'Primary Email')}
          </h3>
          <ContactInformationEmail email={primaryEmail} />
        </div>
      )}
      {!!otherEmails?.length && (
        <div className="desktop:mt-10 mobile:mt-6">
          <h3 className="h5">
            {t('rejoin-contact-information.alternate-email', 'Alternate Email')}
          </h3>
          {otherEmails.map(email => (
            <div key={email.address}>
              <ContactInformationEmail className="mt-2" email={email} />
            </div>
          ))}
        </div>
      )}

      {primaryPhone && (
        <div className="desktop:mt-10 mobile:mt-6">
          <h3 className="h5">
            {t('rejoin-contact-information.phone-primary', 'Primary Phone')}
          </h3>
          <ContactInformationPhone phone={primaryPhone} />
        </div>
      )}
      {!!otherPhones?.length && (
        <div className="desktop:mt-10 mobile:mt-6">
          <h3 className="h5">
            {t('rejoin-contact-information.alternate-phone', 'Alternate Phone')}
          </h3>
          {otherPhones.map(phone => (
            <ContactInformationPhone
              className="mt-2"
              phone={phone}
              key={phone.number}
            />
          ))}
        </div>
      )}

      {primaryAddress && (
        <div className="desktop:mt-10 mobile:mt-6">
          <ContactInformationAddress
            address={primaryAddress}
            label={t(
              'rejoin-form-contact-information.address-primary',
              'Primary Address'
            )}
          />
        </div>
      )}
      {!!otherAddresses?.length && (
        <>
          {otherAddresses.map(address => {
            return (
              <div
                className="desktop:mt-10 mobile:mt-6"
                key={`${address.lineOne} ${address.type}`}
              >
                <ContactInformationAddress
                  address={address}
                  label={t(
                    'rejoin-form-contact-information.address-alternate',
                    'Alternate Address'
                  )}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default RejoinFormComponent;
