import React from 'react';

import Title from '@components/Title';

import { useTranslation } from '@external/react-i18next';

const RejoinFormHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title>{t('rejoin-form.title', 'Rejoin or change clubs')}</Title>
      <span className="text-sm" data-testid="rejoin-title-info">
        {t(
          'rejoin-form.title.info',
          'Whether you’re returning to Rotary after an absence or moving and want to get involved in your new community, we can help connect you to a club.'
        )}
      </span>
    </>
  );
};

export default RejoinFormHeader;
