import { TFunction } from 'i18next';

import { ResumeItem } from '@typings/graphql';

export const getOrganizationName = (
  organizationGroup: ResumeItem,
  t?: TFunction
) => {
  const { organizationName, organizationType } = organizationGroup;

  return organizationType === 'District' && t
    ? t('resume.district-title', 'District {{riDistrictId}}', {
        riDistrictId: organizationName,
      })
    : organizationName;
};
