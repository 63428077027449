import { TFunction } from 'i18next';

export const getListProfessionSource = (t: TFunction) => {
  return [
    {
      name: t(
        'club-meeting-preference-profession.select.option1',
        'Architecture and Engineering'
      ),
      id: 'club-meeting-preference-profession.select.option1',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option2',
        'Arts, Design, Entertainment, Sports, and Media'
      ),
      id: 'club-meeting-preference-profession.select.option2',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option3',
        'Aviation Professional and Travel Services'
      ),
      id: 'club-meeting-preference-profession.select.option3',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option4',
        'Building and Grounds Cleaning and Maintenance'
      ),
      id: 'club-meeting-preference-profession.select.option4',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option5',
        'Business, Insurance, Real Estate, and Financial Operations'
      ),
      id: 'club-meeting-preference-profession.select.option5',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option6',
        'Business Owner/Entrepreneur'
      ),
      id: 'club-meeting-preference-profession.select.option6',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option7',
        'Communication, Social Media, and Marketing Professional'
      ),
      id: 'club-meeting-preference-profession.select.option7',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option8',
        'Community and Social Service '
      ),
      id: 'club-meeting-preference-profession.select.option8',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option9',
        'Construction and Extraction'
      ),
      id: 'club-meeting-preference-profession.select.option9',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option10',
        'Consultant'
      ),
      id: 'club-meeting-preference-profession.select.option10',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option11',
        'Education, Research, and Library Professional'
      ),
      id: 'club-meeting-preference-profession.select.option11',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option12',
        'Farming, Fishing, and Forestry'
      ),
      id: 'club-meeting-preference-profession.select.option12',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option13',
        'Food Preparation and Serving'
      ),
      id: 'club-meeting-preference-profession.select.option13',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option14',
        'Government (Federal, Local, Foreign Service)'
      ),
      id: 'club-meeting-preference-profession.select.option14',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option15',
        'Healthcare Practitioners and Technical Support'
      ),
      id: 'club-meeting-preference-profession.select.option15',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option16',
        'Hospitality Services and Management'
      ),
      id: 'club-meeting-preference-profession.select.option16',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option17',
        'Installation, Maintenance, and Repair'
      ),
      id: 'club-meeting-preference-profession.select.option17',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option18',
        'IT, Computer, and Mathematics'
      ),
      id: 'club-meeting-preference-profession.select.option18',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option19',
        'Law Enforcement, Protective Services, Military Professional'
      ),
      id: 'club-meeting-preference-profession.select.option19',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option20',
        'Legal Professional'
      ),
      id: 'club-meeting-preference-profession.select.option20',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option21',
        'Life, Physical, and Social Science'
      ),
      id: 'club-meeting-preference-profession.select.option21',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option22',
        'Management'
      ),
      id: 'club-meeting-preference-profession.select.option22',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option23',
        'Meetings and Events'
      ),
      id: 'club-meeting-preference-profession.select.option23',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option24',
        'Office and Administration Professional'
      ),
      id: 'club-meeting-preference-profession.select.option24',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option25',
        'Peacebuilding and Conflict Resolution Professionals'
      ),
      id: 'club-meeting-preference-profession.select.option25',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option26',
        'Personal Care and Service'
      ),
      id: 'club-meeting-preference-profession.select.option26',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option27',
        'Production and Manufacturing Professional'
      ),
      id: 'club-meeting-preference-profession.select.option27',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option28',
        'Project Management'
      ),
      id: 'club-meeting-preference-profession.select.option28',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option29',
        'Sales and Related Professional'
      ),
      id: 'club-meeting-preference-profession.select.option29',
    },
    {
      name: t('club-meeting-preference-profession.select.option30', 'Student'),
      id: 'club-meeting-preference-profession.select.option30',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option31',
        'Transportation, Logistics, and Materials Moving'
      ),
      id: 'club-meeting-preference-profession.select.option31',
    },
    {
      name: t(
        'club-meeting-preference-profession.select.option32',
        'Unemployed'
      ),
      id: 'club-meeting-preference-profession.select.option32',
    },
    {
      name: t('club-meeting-preference-profession.select.option33', 'Retired'),
      id: 'club-meeting-preference-profession.select.option33',
    },
  ];
};
