import React from 'react';
import { Email } from '@typings/graphql';

interface Props {
  email: Email;
  className?: string;
}

const ContactInformationEmail: React.FC<Props> = ({ email, className }) => {
  return (
    <a className={className} href={`mailto:${email.address}`}>
      {email.address}
    </a>
  );
};

export default ContactInformationEmail;
