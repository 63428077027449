import { TFunction } from 'i18next';

export const getOrganizationTypeSuffix = (
  organizationType: string,
  t?: TFunction
) => {
  if (!t) return '';

  if (organizationType === 'Club') {
    return `(${t('club-type.rotary-club', 'Rotary Club')})`;
  }

  if (organizationType === 'Rotaract Club') {
    return `(${t('club-type.rotaract-club', 'Rotaract Club')})`;
  }

  return '';
};
