import { useLazyQuery, useQuery } from '@apollo/client';

import { fetchStates } from '@repositories/disCountry';

import { StatesQuery, StatesQueryVariables } from '@typings/operations';

export const useDISStates = (countryCode: string) =>
  useQuery<StatesQuery, StatesQueryVariables>(fetchStates, {
    variables: {
      countryCode,
    },
  });

export const useDISStatesLazy = () =>
  useLazyQuery<StatesQuery, StatesQueryVariables>(fetchStates);
