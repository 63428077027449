import { BaseAddress, Maybe } from '@typings/graphql';

export const getAddress = (
  accomodatioName: Maybe<string> = '',
  address?: Maybe<BaseAddress>
): string[] => {
  if (!address) {
    return [];
  }
  const {
    lineOne,
    lineTwo,
    lineThree,
    city,
    postalCode,
    country,
    state,
    internationalProvince,
  } = address;

  return [
    accomodatioName,
    [lineOne, lineTwo, lineThree].filter(Boolean).join(' '),
    [city, postalCode, country].filter(Boolean).join(', '),
    state || internationalProvince,
  ].filter(Boolean) as string[];
};
