import React, { useLayoutEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';

import Element, { ElementClasses, ElementProps } from './Element';

import { useTranslation } from '@external/react-i18next';

export interface TextareaProps extends ElementProps {
  characters?: number;
  classes?: ElementClasses;
  areaclasses?: string;
  hasMargins?: boolean;
  displayCharLimit?: boolean;
  isAutoResizable?: boolean;
}

const TextArea: React.FC<TextareaProps> = ({
  name,
  characters,
  areaclasses = 'h-88 py-4 px-2',
  displayCharLimit = true,
  isAutoResizable = false,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const [textAreaValue, setTextAreaValue] = useState(field.value);
  const { setFieldValue } = useFormikContext();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const getCharNumber = (charNumber: number) =>
    charNumber - (textAreaValue?.length || 0);

  useLayoutEffect(() => {
    const textArea = textAreaRef.current;

    if (textArea && isAutoResizable) {
      textArea.style.height = '0px';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [textAreaValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, maxLength } = e.target;
    if (typeof characters !== 'undefined' || maxLength !== -1) {
      if (value.length > maxLength) return false;
    }
    setFieldValue(field.name, value);
    return setTextAreaValue(value);
  };

  return (
    <Element name={name} {...restProps}>
      <textarea
        {...field}
        {...restProps}
        id={name}
        data-testid={name}
        maxLength={characters}
        value={textAreaValue}
        ref={textAreaRef}
        onChange={e => {
          handleChange(e);
        }}
        className={classNames(
          areaclasses,
          'border border-gray-300 rounded-sm w-full resize-none',
          'focus-visible:outline-0 focus:shadow-none',
          {
            'text-gray-400': restProps.disabled,
            'border-red-600 bg-red-100': meta.error && meta.touched,
            'focus:border-dark-blue-400': !(meta.error && meta.touched),
            'overflow-y-hidden': isAutoResizable,
          }
        )}
      />
      {characters && displayCharLimit && (
        <p className="mt-3 mb-0 text-xs leading-xs text-gray-500 text-right">
          {t('form.field.max-char', '{{number}} characters left', {
            number: getCharNumber(characters),
          })}
        </p>
      )}
    </Element>
  );
};

export default TextArea;
