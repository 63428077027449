import { useLazyQuery } from '@apollo/client';

import fetchIndividualMemberships from '@queries/fetchIndividualMembershipsQuery';

import {
  FetchIndividualMembershipsQuery,
  FetchIndividualMembershipsQueryVariables,
} from '@typings/operations';

export const useFetchMembershipsHistory = () =>
  useLazyQuery<
    FetchIndividualMembershipsQuery,
    FetchIndividualMembershipsQueryVariables
  >(fetchIndividualMemberships);
