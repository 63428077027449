import React from 'react';

import { TFunction } from 'i18next';

import Select from '@components/Formik/Select';

import { getListProfessionSource } from '@domain/leads/join-form/professionList';
import { ProfessionSource } from '@domain/leads/join-form/types';

import { useTranslation } from '@external/react-i18next';

const Profession: React.FC = () => {
  const { t } = useTranslation();
  const professionOptions = (groups: ProfessionSource[]) =>
    groups.map(({ name, id }) => ({ label: name, value: id }));

  const getProfessionSourceGroups = (t: TFunction) => {
    const listProfessionGroup: Array<ProfessionSource> = getListProfessionSource(
      t
    );
    return professionOptions(listProfessionGroup);
  };
  return (
    <Select
      name="profession"
      label={t(
        'club-meeting-preference.form.profession',
        'What is your profession?'
      )}
      options={getProfessionSourceGroups(t)}
      clearable
      searchable
    />
  );
};

export default Profession;
