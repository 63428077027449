import { getOrganizationLocationAndSuffix } from './getOrganizationLocationAndSuffix';
import { getOrganizationName } from './getOrganizationName';

import { getFormattedDate } from '@utils/datetime';

import { Maybe, ResumeItem } from '@typings/graphql';

const getJoinClubHeading = (joinDate: string, group: ResumeItem) => {
  const locationAndSuffix = getOrganizationLocationAndSuffix(group);

  const formattedHeading = `${getOrganizationName(group)} ${
    locationAndSuffix ? `, ${locationAndSuffix}` : ''
  }`;

  return `${getFormattedDate(joinDate, 'DD MMM YYYY')} (${formattedHeading})`;
};

export const getFormattedOriginalAndCurrentClubs = (
  dates: {
    originalJoinDate: Maybe<string> | undefined;
    currentClubJoinDate: Maybe<string> | undefined;
  },
  resumeRolesGroups: ResumeItem[]
) => {
  const { originalJoinDate, currentClubJoinDate } = dates;

  return resumeRolesGroups.reduce((acc, group) => {
    group.roles.forEach(role => {
      if (originalJoinDate && role.startDate === originalJoinDate) {
        // eslint-disable-next-line no-param-reassign
        acc.originalJoinClub = getJoinClubHeading(originalJoinDate, group);
      }
      if (currentClubJoinDate && role.startDate === currentClubJoinDate) {
        // eslint-disable-next-line no-param-reassign
        acc.currentJoinClub = getJoinClubHeading(currentClubJoinDate, group);
      }
    });
    return acc;
  }, {} as { originalJoinClub: string; currentJoinClub: string });
};
