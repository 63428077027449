import { gql } from '@apollo/client';

export default gql`
  query fetchClubsAffiliations($individualId: String!) {
    getIndividual(id: $individualId) {
      firstName
      lastName
      photoUri
      clubAffiliations {
        clubId
        clubName
        district {
          id
          riDistrictId
          isHomeDistrict
        }
        affiliations {
          ... on LeadershipAffiliation {
            leadershipRole
          }
          ... on MembershipAffiliation {
            membershipType
          }
        }
      }
    }
  }
`;
