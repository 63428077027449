import { QueryResult, useQuery } from '@apollo/client';

import { fetchRejoinInfoQuery } from '../queries';

import {
  FetchRejoinInfoQuery,
  FetchRejoinInfoQueryVariables,
} from '@typings/operations';

type FetchRejoinInfoResult = Pick<
  QueryResult<FetchRejoinInfoQuery>,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const useFetchRejoinInfo = (
  individualId: string
): FetchRejoinInfoResult =>
  useQuery<FetchRejoinInfoQuery, FetchRejoinInfoQueryVariables>(
    fetchRejoinInfoQuery,
    {
      variables: { id: individualId },
    }
  );
