import { gql } from '@apollo/client';

export const fetchStates = gql`
  query States($countryCode: String) {
    states(countryCode: $countryCode) {
      id
      countryId
      name
    }
  }
`;
