import { ProgramDefinition, ProgramFieldDefinition } from '@typings/graphql';
import { selectFieldsFromProgramDefinition } from '.';

export const getFieldDefinitionById = (
  programDefinition: ProgramDefinition,
  fieldId: string
): ProgramFieldDefinition =>
  selectFieldsFromProgramDefinition(programDefinition).filter(
    f => f.fieldId === fieldId
  )[0];
