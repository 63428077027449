import React, { useState } from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import Divider from '@components/Divider';
import TextArea from '@components/Formik/TextArea';
import CheckboxPrompt from '@presenters/web/components/CheckboxPrompt';
import PrivacyPolicy from '@presenters/web/components/Leads/PrivacyPolicy/PrivacyPolicy';
import Profession from '@presenters/web/components/Leads/Profession/Profession';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const RejinEditFormComponent: React.FC = () => {
  const { t } = useTranslation();
  const [isCommunicationConfirmed, setIsCommunicationConfirmed] = useState(
    false
  );

  const thankyou = () => {
    localizedNavigate('/join-form/rejoin/thank-you');
  };

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {() => {
        return (
          <Form>
            <div className="desktop:mt-10 mobile:mt-6">
              <CheckboxPrompt
                isChecked={isCommunicationConfirmed}
                toggleIsChecked={() =>
                  setIsCommunicationConfirmed(!isCommunicationConfirmed)
                }
                prompt={t(
                  'rejoin-contact-information.online.meeting-confirmation',
                  'I am interested in an online meeting option, if available.'
                )}
                mobileTextLeft
              />
            </div>
            <Divider />
            <span className="text-sm" data-testid="rejoin-form-info">
              {t(
                'rejoin-form-edit.description.info',
                'Please tell us a little bit more about yourself so we can match you with the best club for you.'
              )}
            </span>
            <div className="max-w-lg desktop:mt-10 mobile:mt-6">
              <Profession />
            </div>
            <div className="max-w-lg desktop:mt-10 mobile:mt-6">
              <TextArea
                data-testid="rejoin-form-preference-label"
                name="rejoin-form-preference-label"
                label={t('rejoin-form-edit.form.label', 'Additional comments')}
                characters={250}
                areaclasses="h-40"
              />
            </div>
            <div className="max-w-lg desktop:mt-10 mobile:mt-6">
              <PrivacyPolicy />
            </div>
            <div className="max-w-lg">
              <Button
                full
                className="mb-6 desktop:mt-16 mobile:mt-6"
                clickHandler={() => {
                  thankyou();
                }}
              >
                {t('rejoin-form-edit.submit-label', 'Submit')}
              </Button>
              <Button full text type="button">
                {t('rejoin-form-edit.cancel-label', 'Cancel')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RejinEditFormComponent;
