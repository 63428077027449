import React from 'react';

import { useTranslation } from '@external/react-i18next';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span className="text-sm">
      {t(
        'club-meeting-preference.form.privacy-policy',
        'The personal data on this form will be shared with Rotary club and district officers. It may also be used for other membership related activities. If you are referring someone to join a club in Great Britain or Ireland, it will be shared with Rotary International in Great Britain and Ireland. The personal data collected on this form is subject to <a target="_blank" href="https://my.rotary.org/en/privacy-policy">Rotary’s Privacy Policy. </a>'
      )}
    </span>
  );
};

export default PrivacyPolicy;
