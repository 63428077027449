import { sortBy } from 'lodash';

import { ResumeItem } from '@typings/graphql';

export const orderResumeRolesGroup = (resumeRolesGroups: ResumeItem[]) => {
  const order = [
    'Rotary International',
    'The Rotary Foundation',
    'Regional Group',
    'District',
    'Club',
  ];

  const rolesGroupsByOrder: ResumeItem[] = [];
  const restGroups: ResumeItem[] = [];

  resumeRolesGroups.forEach(item => {
    const { organizationName, organizationType } = item;

    if (order.includes(organizationName) || order.includes(organizationType)) {
      rolesGroupsByOrder.push(item);
    } else {
      restGroups.push(item);
    }
  });

  return [
    ...sortBy(rolesGroupsByOrder, [
      ({ organizationType }) => order.indexOf(organizationType),
      'organizationName',
    ]),
    ...sortBy(restGroups, 'organizationName'),
  ];
};
