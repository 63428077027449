import React from 'react';

import classNames from 'classnames';

import { getPhoneTypeLabel } from '@use-cases/profile';

import { useDISCountries } from '@repositories/disCountry';

import { getCountryCode } from '@utils/getCountryCode';

import { useTranslation } from '@external/react-i18next';

import { PhoneNumber, PhoneType } from '@typings/graphql';

interface Props {
  phone: PhoneNumber;
  className?: string;
  phoneNumberColor?: string;
}

const ContactInformationPhone: React.FC<Props> = ({
  phone,
  className,
  phoneNumberColor,
}) => {
  const { data } = useDISCountries();
  const { t } = useTranslation();
  const country = data?.countries.find(
    country => country.id === phone.countryId
  );
  const countryPhoneCode = getCountryCode(country?.shortCode);
  const fullPhoneNumber = `00${countryPhoneCode && `${countryPhoneCode}`}${
    phone.number
  }`;
  const formattedPhoneNumber = `+${countryPhoneCode && `${countryPhoneCode}`} ${
    phone.number
  }`;
  return (
    <div className={className}>
      <a
        aria-hidden="true"
        className="inline-block tablet:hidden"
        href={`tel:${fullPhoneNumber}`}
      >
        {formattedPhoneNumber}
      </a>
      <span
        className={classNames(
          'hidden tablet:inline-block',
          !phoneNumberColor ? 'text-dark-blue-400' : phoneNumberColor
        )}
      >
        {formattedPhoneNumber}
      </span>
      {phone.extension
        ? `, ${t('contact-information.phone-extension', 'ext')}. ${
            phone.extension
          }`
        : ''}
      <span> ({getPhoneTypeLabel(t, phone.type || PhoneType.Business)})</span>
    </div>
  );
};

export default ContactInformationPhone;
