import React from 'react';

import RequireLogin from '@components/Auth/RequireLogin';
import { OneColumn } from '@components/Layouts/OneColumn';
import NotFound from '@components/Routing/NotFound';
import {
  RejoinFormComponent,
  RejoinFormHeader,
  RejonEditFormComponent,
} from '@presenters/web/pages/Leads/RejoinForm';

import { FEATURE_LEADS_JOIN_FORMS, isEnabled } from '@utils/features';

const RejoinPage: React.FC = () => {
  const isLeadJoinFormEnabled = isEnabled(FEATURE_LEADS_JOIN_FORMS);
  if (!isLeadJoinFormEnabled) {
    return <NotFound />;
  }
  return (
    <RequireLogin>
      <OneColumn className="mb-20">
        <RejoinFormHeader />
        <RejoinFormComponent />
        <RejonEditFormComponent />
      </OneColumn>
    </RequireLogin>
  );
};

export default RejoinPage;
