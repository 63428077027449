import { gql } from '@apollo/client';

const Address = gql`
  fragment Address on Address {
    type
    lineOne
    lineTwo
    lineThree
    country
    countryId
    city
    stateId
    state
    postalCode
  }
`;

const Phone = gql`
  fragment Phone on PhoneNumber {
    country
    countryId
    extension
    number
    type
  }
`;

export const fetchRejoinInfoQuery = gql`
  query fetchRejoinInfo($id: String!) {
    rejoinInfo: getIndividual(id: $id) {
      firstName
      middleName
      lastName
      isClubOrDistrictOfficer
      isClubOfficer
      primaryEmail {
        type
        address
        isSignin
      }
      otherEmails {
        type
        address
        isSignin
      }
      primaryPhone {
        ...Phone
      }
      otherPhones {
        ...Phone
      }
      primaryAddress {
        ...Address
      }
      otherAddresses {
        ...Address
      }
    }
  }
  ${Address}
  ${Phone}
`;
