import { orderBy } from 'lodash';

import { ResumeRole } from '@typings/graphql';

export interface ResumeRoleItem extends ResumeRole {
  terms: { startDate: string; endDate?: string | null }[];
}

const getDaysDiff = (t1: string, t2?: string | null) => {
  if (!t2) return Infinity;

  const date1 = new Date(t1);
  const date2 = new Date(t2);
  const diffTime = Math.abs(Number(date2) - Number(date1));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const mergeRoles = (roles: ResumeRole[]) => {
  const onlineRoles = roles.filter(
    ({ isShowOnline }) => isShowOnline === 'True'
  );

  // If user served in the same role multiple times
  // merge these roles which has no gaps (roles goes one by one according to the start/end dates).
  // If there is a gap in the years between roles => add separate record to the array.
  const mergedRoles = orderBy(
    onlineRoles,
    ['role', 'committeeName', 'startDate'],
    ['asc', 'asc', 'desc']
  ).reduce((acc, curr) => {
    // Handle first element
    if (acc.length === 0) {
      acc.push({ ...curr });
    } else {
      const prev = acc[acc.length - 1];

      const isSameRole =
        prev.committeeName === curr.committeeName && prev.role === curr.role;

      // If previous (newest) role started right after the current (erliest) => update start date as erliest
      // Othervise => push new item to accumulator
      if (isSameRole && getDaysDiff(prev.startDate, curr.endDate) === 1) {
        prev.startDate = curr.startDate;
      } else {
        acc.push({ ...curr });
      }
    }

    return acc;
  }, [] as ResumeRole[]);

  // Go through the mergedRoles array and return the list of unique roles.
  // If there is duplicates of the roles => leave only one and update the terms array.
  // Terms property should contain the terms of the role-duplicates.
  const rolesWithTerms = mergedRoles.reduce((acc, curr) => {
    // Handle first element
    if (acc.length === 0) {
      acc.push({
        ...curr,
        terms: [{ startDate: curr.startDate, endDate: curr.endDate }],
      });
    } else {
      const prev = acc[acc.length - 1];

      // If previous role is the same as the current =>
      // push to the terms array the start/end dates of the current item
      if (
        prev.committeeName === curr.committeeName &&
        prev.role === curr.role
      ) {
        prev.terms?.push({ startDate: curr.startDate, endDate: curr.endDate });
      } else {
        acc.push({
          ...curr,
          terms: [{ startDate: curr.startDate, endDate: curr.endDate }],
        });
      }
    }
    return acc;
  }, [] as ResumeRoleItem[]);

  return orderBy(rolesWithTerms, 'startDate', 'desc');
};
